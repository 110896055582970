#first-NFT{
    
    border-radius: 100px;
    height: 30vh;
    animation: animate 5s linear infinite;
    perspective: 250px;
}
@keyframes animate {
0%{
   transform: rotateY(0deg);
}
100%{
    transform: rotateY(360deg);
}
}
.NFT{
    margin-top: 10px;
}