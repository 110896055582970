.file-upload {
    
    width: 44%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 44px;
  
  }
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    
  }
  
  .image-upload-wrap {
    margin-top: 1px;
    border: 4px dashed #183a85;
    position: relative;
    border-radius: 44px;
  }
  
  .image-upload-wrap:hover {
    background-color: #2acce9;;
    border: 4px dashed #ffffff;
  }
  
  .drag-text {
    text-align: center;
  }
  
  .drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #038f49;
    padding: 60px 0;
  }
  .rounded {
    border-radius: 1rem
}

.nav-pills .nav-link {
    color: #555
}

.nav-pills .nav-link.active {
    color: white
}

input[type="radio"] {
    margin-right: 5px
}

.bold {
    font-weight: bold
}
.file-and-payment{
  color: #FFFFFF;
  margin-top: 23px;
 margin-bottom: 23px;
  
}