

svg {
  height: 95vh;
  width: 95vw; }

.st0, .st1, .st2, .st3 {
  fill:none;
  stroke: #2acce9;
  stroke-width: 42;
  stroke-miterlimit: 10; }

.st0 {
  stroke-dasharray: 12.1947,12.1947,12.1947,12.1947,12.1947,12.1947; }

.st1 {
  stroke-dasharray: 1,0,0,0,0,2; }

.st2 {
  stroke-linecap: square;
  stroke-dasharray: 120, 20, 110, 20, 140; }

.st3 {
  stroke-width: 16;
  stroke-linecap: square; }

#text-stroke{ 
 height: 10px;
 width: 10px;
}

#wing1{
	 	 	transform-origin:center;
	 	 	animation: flap 0.9s ease-out infinite alternate;
	 	}	
#wing2{
	 	 	transform-origin:center;
	 	 	animation: flap 0.9s ease-in infinite alternate;
	 	}	 	
@keyframes flap{
	 		50%{
	 	 	transform:scaleX(-1) rotate(-45deg) translate(-40px,-40px);

	 		}
	 	}
