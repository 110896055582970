
canvas{
  margin-top: 10px;
  width: 50vh;
}
.title{
  color: white;
}

.nk-block-text p {
  color:slategrey ;
}
